import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const PageImage = styled(Img)`
  height: 100vh;
  max-height: calc(2 / 5 * 100vh);
  min-height: 20rem;
  object-fit: cover;
  object-position: 0% 0%;
`;

const ImageContainer = styled.div`
  margin-bottom: 4rem;
  position: relative;

  &:after {
    background-color: white;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.35;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export default (props) => {
  const { sizes } = props;
  return (
    <ImageContainer>
      <PageImage sizes={sizes} />
    </ImageContainer>
  );
};

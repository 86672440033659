import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { LanguageContext } from '../components/language-context-provider';
import PageImage from '../components/page-image';
import TextElement from '../components/text-element';
import Text from '../components/text';
import theme, { media } from '../theme';

const PageTitle = styled(Text.H1)`
  display: flex;
  flex-direction: column;
  height: 6rem;
  margin: 0;
  justify-content: center;
  text-align: center;
`;

const PageWrapper = styled.main`
  flex-grow: 1;
`;

const Padding = styled.div`
  padding: 0 ${theme.spacing[0]};

  ${media[0]`
    padding: 0 ${theme.spacing[1]};
  `};
`;

const ContentPage = ({ data }) => {
  const { markdownRemark, images } = data;
  const { sizes } = images.edges.filter(
    (image) => image.node.name
      === markdownRemark.frontmatter.image.match(
        /(\/images\/)(.*)(\.jpg|.png|.gif|.jpeg|.tiff|.webp)/,
      )[2],
  )[0].node.childImageSharp;

  return (
    <Layout>
      <LanguageContext.Consumer>
        {({ language }) => (
          <PageWrapper>
            <PageTitle>{markdownRemark.frontmatter.title[language]}</PageTitle>
            <PageImage sizes={sizes} />
            <Padding>
              <TextElement
                padding="0 0"
                margin="0 auto 4rem auto"
                markdown={markdownRemark.frontmatter.content[language]}
              />
            </Padding>
          </PageWrapper>
        )}
      </LanguageContext.Consumer>
    </Layout>
  );
};

export default ContentPage;

export const pageQuery = graphql`
  query ContentPageQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        image
        title {
          en
          it
        }
        description {
          en
          it
        }
        content {
          en
          it
        }
      }
    }

    images: allFile(
      filter: { internal: { mediaType: { regex: "/(image)/" } } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            sizes(maxWidth: 1920, quality: 70) {
              ...GatsbyImageSharpSizes_withWebp
            }
          }
        }
      }
    }
  }
`;
